import React, { Component } from 'react';
import './App.css';
import ERC20 from '../abis/CodefiERC20.json';
import Bridge from '../abis/Bridge.json';
import Handler from '../abis/ERC20Handler.json';
import ENV from '../env.json';
import Navbar from './Navbar';
import Main from './Main';
import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
const ethers = require('ethers');


/*
 * Note: Credentials NOT to be used to production
 */
const username = "ubs_poc";
const password = "n1C3*3uo#ZqD";

/*
 * Used to configure Amplify's connection to STACS' AWS authentication service
 * 
 * @param region              AWS Cognito App Client Region
 * @param userPoolId          AWS Cognito Pool ID
 * @param userPoolWebClientId AWS Cognito App Client ID
 */
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_NfbgJPpy7",
    userPoolWebClientId: "uosgc5ntnp1fi8800prr066hg"
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: Object.keys(ENV.keys)[0],
      network: '-1',
      accountBalance: '',
      lockedTokens: '',
      fromAccount: '',
      toAccount: '',
      stacsBalance: 0,
      stacsWallet: 'a1ae2cb0cec1eb1d1e41694d68b6c0f12a64bba4',
      disableTransfer: false,
      status: '',
      selectedTokenAddress: ERC20.networks[ENV.paymentsNetworkId].address,
      tokenName: '',
      idToken: ''
    }

    this.transfer = this.transfer.bind(this);
    this.updatePaymentsBalance = this.updatePaymentsBalance.bind(this);
    this.updateStacsBalance = this.updateStacsBalance.bind(this);
    this.initPaymentsToken = this.initPaymentsToken.bind(this);
  }

  async componentWillMount() {
    this.setState({ idToken: await this.getUserSession() });
    await this.loadBlockchainData();
  }

  /*
    * Used to retrieve json web token to access STACS' blockchain APIs (validity - 60mins)
    * 
    * @return jwt token
  */
  async getUserSession() {
    try {
      const user = await Auth.signIn(username, password);
      user.getSignInUserSession();
      const session = await Auth.currentSession();
      const jwtToken = session.getIdToken().getJwtToken();
      return jwtToken;
    } catch (err) {
      console.log("getUserSession error: ", err);
    }
  }

  async loadBlockchainData() {
    const paymentsNetworkId = ENV.paymentsNetworkId;
    this.setState({ paymentsNetworkId });
    const provider = await this.getProvider();
    this.setState({ provider });
    const gasPrice = ethers.utils.bigNumberify(0).mul(ethers.utils.bigNumberify('1000000000'));
    this.setState({ gasPrice });

    const handlerNetworkData = Handler.networks[paymentsNetworkId];
    const erc20NetworkData = ERC20.networks[paymentsNetworkId];
    if (handlerNetworkData && erc20NetworkData) {
      const handlerContractAddress = handlerNetworkData.address;
      this.setState({ handlerContractAddress });
      const erc20ContractAddress = erc20NetworkData.address;
      await this.initPaymentsToken(erc20ContractAddress, false);
    } else {
      window.alert('Error while initializing the blockchain data');
    }

    this.updateStacsBalance(this.state.stacsWallet);
  }

  async getWallet(privateKey) {
    return new ethers.Wallet(privateKey);
  }

  async getProvider() {
    let providerJsonRpc = ENV.jsonRpcProvider.url;
    if (ENV.jsonRpcProvider.user) {
      providerJsonRpc = {
        url: ENV.jsonRpcProvider.url,
        user: ENV.jsonRpcProvider.user,
        password: ENV.jsonRpcProvider.password
      }
    }

    return new ethers.providers.JsonRpcProvider(providerJsonRpc);
  }

  async getWriteContractInstance(
    contractJson,
    address,
    contractAddress
  ) {
    const privKey = ENV.keys[address];
    if (privKey) {
      const ethersProvider = this.state.provider;
      const contractAbi = contractJson.abi
      let contract = contractAddress;
      if (!contractAddress) {
        contract = contractJson.networks[this.state.paymentsNetworkId].address;
      }
      const contractObj = new ethers.Contract(contract, contractAbi, ethersProvider);
      const wallet = await this.getWallet(privKey);
      const fromSigner = wallet.connect(ethersProvider)
      const fromSignerContract = contractObj.connect(fromSigner)
      return fromSignerContract
    } else {
      window.alert('Invalid address, there is no signing key.');
    }
  }

  async getReadContractInstance(
    contractJson,
    contractAddress
  ) {
    const ethersProvider = this.state.provider;
    const contractAbi = contractJson.abi;
    let contract = contractAddress;
    if (!contractAddress) {
      contract = contractJson.networks[this.state.paymentsNetworkId].address;
    }
    return new ethers.Contract(contract, contractAbi, ethersProvider);
  }

  async startListenerTransferLockIntoEscrow(fromAccount) {
    console.log('Starting listener for ERC Token contract - Transfer (lock)');
    const erc20ReadContractInstance = this.state.erc20ReadContractInstance;
    const filterTo = erc20ReadContractInstance.filters.Transfer(fromAccount, this.state.handlerContractAddress);

    erc20ReadContractInstance.on(filterTo, (sender, recipient, amount, event) => {
      event.removeListener();
      console.log('Token contract transfer(lock) event received');
      console.log(sender, recipient, amount.toString());
      if (recipient === this.state.handlerContractAddress) { // lock event
        console.log('Tokens lock successful');
        this.setState({ status: 'Transfer in Progress (2/2 mint tokens)' });
        this.mint(this.state.toAccount, this.truncateDecimals(amount));
      }
    });
  }

  async startListenerTransferReleaseToUser(toAccount) {
    console.log('Starting listener for ERC Token contract - Transfer (release)');
    const erc20ReadContractInstance = this.state.erc20ReadContractInstance;
    const filterFrom = erc20ReadContractInstance.filters.Transfer(this.state.handlerContractAddress, toAccount);

    erc20ReadContractInstance.on(filterFrom, (sender, recipient, amount, event) => {
      event.removeListener();
      console.log('Token contract transfer(release) event received');
      console.log(sender, recipient, amount.toString());
      if (sender === this.state.handlerContractAddress) { // withdraw event
        this.updateBalance();
        this.setState({ disableTransfer: false });
        this.setState({ status: 'Tokens transfer (burn & release) successful' });
      }
    });
  }

  async startListenerApprove(fromAccount) {
    console.log('Starting listener for ERC Token contract - Approve');
    const erc20ReadContractInstance = this.state.erc20ReadContractInstance;
    const filterTo = erc20ReadContractInstance.filters.Approval(fromAccount, this.state.handlerContractAddress);
    erc20ReadContractInstance.on(filterTo, (owner, spender, amount, event) => {
      event.removeListener();
      console.log('Token contract approve event received');
      console.log(owner, spender, amount.toString());
      if (this.state.isApproved) { // transferFrom in token contract is calling the approve - this condition will avoid calling deposit twice.
        this.depositTokens(owner, this.state.toAccount, this.truncateDecimals(amount.toString()));
        this.setState({ isApproved: false });
      }
    });
  }

  async initPaymentsToken(tokenAddress, isAllowListed) {
    this.setState({ selectedTokenAddress: tokenAddress });
    const erc20ReadContractInstance = await this.getReadContractInstance(ERC20, tokenAddress);
    this.setState({ erc20ReadContractInstance });
    if (!isAllowListed) {
      this.setState({ status: '1/2 Register token contract to bridge.' });
      this.setState({ disableTransfer: true });
      await this.initTokenToBridge(tokenAddress);
      this.sleep(8000).then(async () => {
        this.setState({ status: '2/2 Initialize token contract.' });
        await this.initTokenContract(tokenAddress);
        this.setState({ status: `Token ${this.state.tokenName} registered successfully.` });
        this.setState({ disableTransfer: false });
      });
    } else {
      await this.initTokenContract();
    }
  }

  async initTokenContract() {
    const name = await this.getTokenSymbol();
    this.setState({ tokenName: name });
    await this.updatePaymentsBalance(this.state.account);
  }

  async getTokenSymbol() {
    const erc20ReadContractInstance = this.state.erc20ReadContractInstance;
    return erc20ReadContractInstance.symbol();
  }

  expandDecimals = (amount, decimals = 2) => {
    return ethers.utils.parseUnits(String(amount), decimals);
  }

  truncateDecimals = (amount, decimals = 2) => {
    return ethers.utils.formatUnits(amount, decimals);
  }

  async approve(fromAccount, amount) {
    const contractObj = await this.getWriteContractInstance(ERC20, fromAccount, this.state.selectedTokenAddress);
    const sendPromise = contractObj.approve(this.state.handlerContractAddress, this.expandDecimals(amount), { gasPrice: this.state.gasPrice });
    console.log('approve transaction sent');
    sendPromise.then(function (transaction) {
      console.log(transaction);
    });
  }

  async getTokenBalance(address) {
    if (address) {
      const erc20ReadContractInstance = this.state.erc20ReadContractInstance;
      const balance = await erc20ReadContractInstance.balanceOf(address);
      return this.truncateDecimals(balance);
    } else {
      return 0;
    }
  }

  async depositTokens(fromAccount, toAccount, amount) {
    toAccount = '0x' + toAccount;
    const data = '0x' +
      ethers.utils.hexZeroPad(ethers.utils.bigNumberify(this.expandDecimals(amount)).toHexString(), 32).substr(2) +    // Deposit Amount        (32 bytes)
      ethers.utils.hexZeroPad(ethers.utils.hexlify((toAccount.length - 2) / 2), 32).substr(2) +    // len(recipientAddress) (32 bytes)
      toAccount.substr(2);
    const resourceId = ENV.resourceId; // to make it compatible with chainbridge

    const contractObj = await this.getWriteContractInstance(Bridge, fromAccount);
    const transaction = await contractObj.deposit(1, resourceId, data, { gasPrice: this.state.gasPrice });
    return transaction;
  }

  async initTokenToBridge(tokenAddress) {
    const contractObj = await this.getWriteContractInstance(Bridge, ENV.admin.pubKey);
    const transaction = await contractObj.adminSetResource(this.state.handlerContractAddress, ENV.resourceId, tokenAddress, { gasPrice: this.state.gasPrice });

    return transaction;
  }

  async adminWithdraw(toAccount, amount) {
    const contractObj = await this.getWriteContractInstance(Bridge, ENV.admin.pubKey);
    const sendPromise = contractObj.adminWithdraw(this.state.handlerContractAddress, this.state.selectedTokenAddress, toAccount, this.expandDecimals(amount), { gasPrice: this.state.gasPrice });
    sendPromise.then(function (transaction) {
      console.log(transaction);
    });
  }

  async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /*
   * Used to mint wUBS PT on the STACS blockchain network
   * 
   * @param amount number of wUBS PT to mint (if amount = 1, number of wUBS PT minted = 1)
   * @return void
   */
  async mint(toAccount, amount) {
    // digitalCurrencyId - pre-created uWBS PT smart contract
    const digitalCurrencyId = "1";

    // investorAddress_01 - wallet address wUBS PT will be minted
    const investorAddress = toAccount;

    // endpoint - STACS blockchain network mint API endpoint
    const endpoint = "https://studios-api.stacs.io/demo/mint";
    const payload = {
      "digitalCurrencyId": digitalCurrencyId,
      "issuanceQuantity": amount.toString(),
      "receivingAddress": investorAddress
    };

    // x-id-token - header required by STACS blockchain API, which consumes the web authentication token
    axios.defaults.headers.common['x-id-token'] = this.state.idToken;

    // response - returns void
    const response = await new Promise((resolve) => {
      axios['post'](endpoint, payload).then(response => {
        resolve(response.data);
        this.setState({ stacsWallet: toAccount });
        this.updateBalance();
        this.setState({ disableTransfer: false });
        this.setState({ status: 'Tokens transfer (lock & mint) successful' });
      }).catch(error => {
        console.log('axiosRequest endpoint: ', endpoint, error);
      });
    });

    return response;
  }

  // Stacs

  /*
   * Used to burn wUBS PT on the STACS blockchain network
   * 
   * @param amount number of wUBS PT to burn (if amount = 1, number of wUBS PT burned = 1)
   * @return void
   */
  async burn(fromAccount, amount) {
    // investorAddress_01 - wallet address wUBS PT will be burned
    const investorAddress = fromAccount;

    // assetCode - STACS digital asset code
    const assetCode = "WRAPPERPT01";

    // assetType - STACS digital asset type
    const assetType = "Digital Currency";

    // assetName - STACS digital asset name
    const assetName = "WRAPPERPT01";

    // burnAddress - STACS blockchain network burn address
    const burnAddress = "000000000000000000000000000000000000000a";

    // endpoint - STACS blockchain network burn API endpoint
    const endpoint = "https://studios-api.stacs.io/demo/burn";
    const payload = {
      "fromAddress": investorAddress,
      "amount": amount,
      "assetCode": assetCode,
      "assetType": assetType,
      "assetName": assetName,
      "toAddress": burnAddress
    };

    // x-id-token - header required by STACS blockchain API, which consumes the web authentication token
    axios.defaults.headers.common['x-id-token'] = this.state.idToken;

    // response - returns void
    const response = await new Promise((resolve) => {
      axios['post'](endpoint, payload).then(response => {
        resolve(response.data);
        this.setState({ stacsWallet: fromAccount });
      }).catch(error => {
        console.log('axiosRequest endpoint: ', endpoint, error);
      });
    });

    return response;
  }

  async transfer(network, fromAccount, toAccount, amount) {
    this.setState({ disableTransfer: true });
    this.setState({ toAccount });
    if (network === 0) {
      await this.startListenerTransferReleaseToUser(toAccount);
      if (parseInt(amount) <= parseInt(this.state.lockedTokens)) {
        this.setState({ status: 'Transfer in Progress (1/2 tokens burn)' });
        await this.burn(fromAccount, amount);
        this.setState({ status: 'Transfer in Progress (2/2 tokens release)' });
        await this.adminWithdraw(toAccount, amount);
      } else {
        this.setState({ disableTransfer: false });
        window.alert('Insufficient balance in escrow account.');
      }
    } else if (network === 1) {
      await this.startListenerApprove(fromAccount);
      await this.startListenerTransferLockIntoEscrow(fromAccount);
      if (parseInt(amount) <= parseInt(this.state.accountBalance)) {
        try {
          this.setState({ isApproved: false });
          this.setState({ status: 'Transfer in Progress (1/2 transfer to escrow)' });
          this.approve(fromAccount, amount)
            .then(result => {
              this.setState({ isApproved: true });
            });
        } catch (err) {
          console.log(err);
        }
      } else {
        this.setState({ disableTransfer: false });
        window.alert('Insufficient balance.');
      }
    } else {
      this.setState({ disableTransfer: false });
      window.alert('Destination network needs to be selected.');
    }
  }

  async updateBalance(paymentAddress, stacsAddress) {
    const queryAccount = paymentAddress ? paymentAddress : this.state.account;
    const accountBalance = await this.getTokenBalance(queryAccount);
    this.setState({ accountBalance });
    const escrowBalance = await this.getTokenBalance(this.state.handlerContractAddress);
    this.setState({ lockedTokens: escrowBalance });
    if (this.state.stacsWallet || stacsAddress) {
      const stacsQueryAccount = stacsAddress ? stacsAddress : this.state.stacsWallet;
      await this.updateStacsBalance(stacsQueryAccount);
    }
  }

  async updatePaymentsBalance(paymentAddress) {
    const queryAccount = paymentAddress ? paymentAddress : this.state.account;
    const accountBalance = await this.getTokenBalance(queryAccount);
    this.setState({ accountBalance });
    const escrowBalance = await this.getTokenBalance(this.state.handlerContractAddress);
    this.setState({ lockedTokens: escrowBalance });
  }

  /*
   * Used to query the balance of wUBS PT on the STACS blockchain network
   * 
   * @return balance number of wUBS PT in the investor's wallet address
   */
  async updateStacsBalance(account) {

    // endpoint - STACS blockchain network query balance API endpoint
    const endpoint = "https://studios-api.stacs.io/demo/balance/" + account.toString();

    // x-id-token - header required by STACS blockchain API, which consumes the web authentication token
    axios.defaults.headers.common['x-id-token'] = this.state.idToken;

    // response - returns a number
    const response = await new Promise((resolve) => {
      axios['get'](endpoint).then(response => {
        resolve(response.data);
      }).catch(error => {
        console.log('axiosRequest endpoint: ', endpoint, error);
      });
    });

    if (response[0]) {
      const balance = response[0].balanceAmount;
      this.setState({ stacsBalance: balance });
      return balance;
    } else {
      this.setState({ stacsBalance: 0 });
      return 0;
    }
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="container-fluid mt-5">
          <div className="row">
            <main role="main" className="col-lg-12 d-flex">
              <Main
                transfer={this.transfer}
                updatePaymentsBalance={this.updatePaymentsBalance}
                updateStacsBalance={this.updateStacsBalance}
                initPaymentsToken={this.initPaymentsToken}
                tokenName={this.state.tokenName}
                tokenContractAddress={this.state.selectedTokenAddress}
                accountBalance={parseInt(this.state.accountBalance)}
                lockedTokens={parseInt(this.state.lockedTokens)}
                account={this.state.account}
                stacsBalance={parseInt(this.state.stacsBalance)}
                disableTransfer={this.state.disableTransfer}
                status={this.state.status} />
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
