import React, { Component } from 'react';
import Popup from './Popup';
class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stacsWallet:'a1ae2cb0cec1eb1d1e41694d68b6c0f12a64bba4',
      paymentsWallet: props.account,
      toAccount: 'a1ae2cb0cec1eb1d1e41694d68b6c0f12a64bba4',
      fromAccount: props.account,
      network: 1,
      tokenContractAddress: props.tokenContractAddress,
      allowedTokenContracts: [props.tokenContractAddress],
      isOpen: false
    }

    this.handleStacsWallet = this.handleStacsWallet.bind(this);
  }

  handleStacsWallet=async(e)=>{
    this.setState({
      stacsWallet: e.target.value
    });

    if (parseInt(this.state.network) === 1) {
      this.setState({ toAccount: this.state.stacsWallet });
    } else if (parseInt(this.state.network) === 0) {
      this.setState({ fromAccount: this.state.stacsWallet });
    }
    await this.props.updateStacsBalance(e.target.value);
  }

  handlePaymentsWallet=(e)=>{
    this.setState({
      paymentsWallet: e.target.value
    });

    if (parseInt(this.state.network) === 1) {
      this.setState({ fromAccount: this.state.paymentsWallet });
    } else if (parseInt(this.state.network) === 0) {
      this.setState({ toAccount: this.state.paymentsWallet });
    }
    this.props.updatePaymentsBalance(e.target.value);
  }

  isAllowListed(tokenContract) {
    return (this.state.allowedTokenContracts.indexOf(tokenContract) > -1);
  }

  handleTokenAddress=(e)=>{
    this.setState({
      tokenContractAddress: e.target.value
    });

    let isAllow = this.isAllowListed(e.target.value);
    if(!isAllow) {
      this.togglePopup();
    }
    this.props.initPaymentsToken(e.target.value, isAllow);
    this.setState({ allowedTokenContracts: [...this.state.allowedTokenContracts, e.target.value]});
  }

  handleChange=(e)=>{
    const networkSelected = e.target.value;
    this.setState({
      network: networkSelected
    });
    if (parseInt(networkSelected) === 1) {
      this.setState({ fromAccount: this.props.account });
    } else if (parseInt(networkSelected) === 0) {
      this.setState({ toAccount: this.props.account });
    }
  }

  setIsOpen = (open) => {
    this.setState({ isOpen: open });
  }

  togglePopup = () => {
    this.setIsOpen(!this.state.isOpen);
  }

  render() {
    const Bold = ({ children }) => <label style={{ fontWeight: 'bold' }}>{children}</label>
    return (
      <div id="content">
        <br/>
        <form onSubmit={(event) => {
          event.preventDefault();
          const networkSelected = parseInt(this.state.network);
          const fromAccount = networkSelected === 0 ? this.state.stacsWallet : this.fromAccount.value;
          const toAccount = networkSelected === 0 ? this.toAccount.value: this.state.stacsWallet;
          const amount = this.amount.value;
          this.props.transfer(networkSelected, fromAccount, toAccount, amount);
        }}>
          <div className="form-group mr-sm-2">
           <label>&nbsp;<Bold>Send Payment Token To:</Bold>&nbsp;</label>
           <input type="radio" value="0" id="ubsNetwork"
           checked={parseInt(this.state.network) === 0}
              onChange={this.handleChange} name="network" />
            <label>&nbsp;UBS Codefi Network &nbsp;&nbsp;</label>

            <input type="radio" value="1" id="stacsNetwork"
            checked={parseInt(this.state.network) === 1}
              onChange={this.handleChange} name="network"/>
            <label>&nbsp;STACS Network</label>
          </div>
          <div className="form-group mr-sm-2">
          <label>&nbsp;<Bold>Token Contract Address:</Bold>&nbsp;</label>
          <input
                id="tokenContractAddress"
                type="text"
                ref={(input) => { this.tokenContractAddress = input }}
                defaultValue={this.state.tokenContractAddress}
                onChange={this.handleTokenAddress}
                className="form-control"
                placeholder="Token Contract Address"
                required />
          </div>
          <div className="form-group mr-sm-2">
            <label>&nbsp;<Bold>From Account:</Bold>&nbsp;</label>
            {parseInt(this.state.network) === 0 ? (
              <div>
              <select className="form-control" value={this.state.stacsWallet} onChange={ this.handleStacsWallet }>
                <option value="a1ae2cb0cec1eb1d1e41694d68b6c0f12a64bba4">0xa1ae2cb0cec1eb1d1e41694d68b6c0f12a64bba4</option>
                <option value="d968d2d3a4acc4bea21c69c55555f8a828d08f39">0xd968d2d3a4acc4bea21c69c55555f8a828d08f39</option>
              </select>
              <label>&nbsp;Balance:&nbsp;{ this.props.stacsBalance }  &nbsp;w{ this.props.tokenName }</label>
              </div>
              ) : (
                <div>
                <input
                id="fromAccount"
                type="text"
                ref={(input) => { this.fromAccount = input }}
                defaultValue={this.state.fromAccount}
                onChange={this.handlePaymentsWallet}
                className="form-control"
                placeholder="From Account"
                required />
                <label>&nbsp;Balance:&nbsp;{ this.props.accountBalance } &nbsp;{ this.props.tokenName }</label>
                </div>
              )}
          </div>
          <div className="form-group mr-sm-2">
          <label>&nbsp;<Bold>To Account:</Bold>&nbsp;</label>
          {parseInt(this.state.network) === 0 ? (
            <div>
            <input
              id="toAccount"
              type="text"
              ref={(input) => { this.toAccount = input }}
              defaultValue={this.state.toAccount}
              onChange={this.handlePaymentsWallet}
              className="form-control"
              placeholder="To Account"
              required />
              <label>&nbsp;Balance:&nbsp;{ this.props.accountBalance }  &nbsp;{ this.props.tokenName }</label>
              </div>
          ) : (
            <div>
            <select className="form-control" value={this.state.stacsWallet} onChange={ this.handleStacsWallet }>
              <option value="a1ae2cb0cec1eb1d1e41694d68b6c0f12a64bba4">0xa1ae2cb0cec1eb1d1e41694d68b6c0f12a64bba4</option>
              <option value="d968d2d3a4acc4bea21c69c55555f8a828d08f39">0xd968d2d3a4acc4bea21c69c55555f8a828d08f39</option>
            </select>
            <label>&nbsp;Balance:&nbsp;{ this.props.stacsBalance }  &nbsp;w{ this.props.tokenName }</label>
              </div>
          )}
          </div>
          <div className="form-group mr-sm-2">
            <label>&nbsp;<Bold>Amount:</Bold>&nbsp;</label>
            <input
              id="amount"
              type="text"
              ref={(input) => { this.amount = input }}
              className="form-control"
              placeholder="Amount"
              required />
          </div>
          <button type="submit" className="btn btn-primary" onClick={this.togglePopup} disabled={ this.props.disableTransfer }>Transfer</button>
        </form>
        <p>&nbsp;</p>
        <div className="form-group mr-sm-2">
          <label>&nbsp;<Bold>Tokens in Escrow (UBS Codefi Network):</Bold>&nbsp;{ this.props.lockedTokens } &nbsp;{ this.props.tokenName }</label>
        </div>
      <div>
    {this.state.isOpen && <Popup
      content={<>
        <b>Transaction Status</b>
        <p>{this.props.status}</p>
        { this.props.disableTransfer ?
         <div className="image-container" row="true">
    <img src={require('../images/processing.gif')} alt="logo"/>
 </div>
  : null
      }
      </>}
      handleClose={this.togglePopup}
    />}
  </div>
      </div>
    );
  }
}

export default Main;
